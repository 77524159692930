/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AppointmentModal_viewer$ref: FragmentReference;
declare export opaque type AppointmentModal_viewer$fragmentType: AppointmentModal_viewer$ref;
export type AppointmentModal_viewer = {|
  +__typename: "Provider",
  +id: string,
  +appointments?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +providerId: ?number,
        +serviceId: ?number,
        +cancellationReason: ?string,
        +payments: ?$ReadOnlyArray<?{|
          +id: string,
          +amount: ?number,
          +status: ?string,
          +currency: ?string,
          +paymentMethod: ?string,
          +type: ?string,
        |}>,
        +fieldsData: ?$ReadOnlyArray<?{|
          +id: string,
          +rowId: number,
          +name: ?string,
          +type: ?string,
          +value: ?string,
        |}>,
        +amountPaid: ?number,
        +clientId: ?number,
        +createdBy: ?any,
        +service: ?{|
          +title: ?string,
          +minSlotsRequired: ?number,
        |},
        +provider: ?{|
          +name: ?string
        |},
        +timeSlots: ?$ReadOnlyArray<?{|
          +id: string,
          +startAt: ?any,
          +endAt: ?any,
          +date: ?any,
          +duration: ?number,
        |}>,
        +startAt: ?any,
        +status: ?string,
        +endAt: ?any,
        +isRecurring: ?boolean,
        +client: ?{|
          +id: string,
          +name: ?string,
          +phoneNumber: ?string,
        |},
      |}
    |}>
  |},
  +places: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +settings: ?{|
          +showServiceCategories: ?boolean
        |},
        +paymentMethods: ?$ReadOnlyArray<?{|
          +id: string,
          +rowId: number,
          +name: ?string,
        |}>,
      |}
    |}>
  |},
  +clients: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +rowId: number,
        +name: ?string,
        +lastActiveAt: ?any,
        +phoneNumber: ?string,
        +profilePhotoUrl: ?string,
      |}
    |}>
  |},
  +timeSlots?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +date: ?any,
        +duration: ?number,
        +startAt: ?any,
        +endAt: ?any,
        +recurringTimeSlotId: ?string,
        +providerId: ?string,
        +serviceId: ?string,
        +clientId: ?string,
      |}
    |}>
  |},
  +services: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +rowId: number,
        +description: ?string,
        +title: ?string,
        +category: ?{|
          +id: string,
          +name: ?string,
        |},
        +appointmentFields: ?$ReadOnlyArray<?{|
          +rowId: number,
          +name: ?string,
          +required: ?boolean,
          +type: ?string,
          +prompt: ?string,
        |}>,
        +duration: ?number,
        +profilePhotoUrl: ?string,
        +isRecurring: ?boolean,
        +providers: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +rowId: number,
              +name: ?string,
              +profilePhotoUrl: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: AppointmentModal_viewer$ref,
|} | {|
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  +__typename: "%other",
  +$refType: AppointmentModal_viewer$ref,
|};
export type AppointmentModal_viewer$data = AppointmentModal_viewer;
export type AppointmentModal_viewer$key = {
  +$data?: AppointmentModal_viewer$data,
  +$fragmentRefs: AppointmentModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UFPageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "appointmentId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchAppointments"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchTimeSlots"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timeSlotsFilterBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "appointments"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "clients"
        ]
      }
    ]
  },
  "name": "AppointmentModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            },
            {
              "kind": "Literal",
              "name": "limit",
              "value": 1
            }
          ],
          "concreteType": "PlaceConnection",
          "kind": "LinkedField",
          "name": "places",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PlaceEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Place",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PlaceSettings",
                      "kind": "LinkedField",
                      "name": "settings",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "showServiceCategories",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PlacePaymentMethod",
                      "kind": "LinkedField",
                      "name": "paymentMethods",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "places(first:1,limit:1)"
        },
        {
          "alias": "clients",
          "args": [
            {
              "kind": "Literal",
              "name": "limit",
              "value": 5000
            }
          ],
          "concreteType": "ClientConnection",
          "kind": "LinkedField",
          "name": "__AppointmentModal_clients_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastActiveAt",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": "__AppointmentModal_clients_connection(limit:5000)"
        },
        {
          "alias": null,
          "args": [
            (v8/*: any*/),
            {
              "kind": "Literal",
              "name": "limit",
              "value": 1000
            }
          ],
          "concreteType": "ServiceConnection",
          "kind": "LinkedField",
          "name": "services",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ServiceEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Service",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ServiceCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AppointmentField",
                      "kind": "LinkedField",
                      "name": "appointmentFields",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "required",
                          "storageKey": null
                        },
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "prompt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/),
                    (v5/*: any*/),
                    (v12/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        (v8/*: any*/)
                      ],
                      "concreteType": "ProviderConnection",
                      "kind": "LinkedField",
                      "name": "providers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProviderEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Provider",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v3/*: any*/),
                                (v5/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "providers(first:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "services(first:1000,limit:1000)"
        },
        {
          "condition": "fetchAppointments",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "appointments",
              "args": null,
              "concreteType": "AppointmentConnection",
              "kind": "LinkedField",
              "name": "__AppointmentModal_appointments_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AppointmentEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Appointment",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cancellationReason",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AppointmentPayment",
                          "kind": "LinkedField",
                          "name": "payments",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "amount",
                              "storageKey": null
                            },
                            (v15/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "currency",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "paymentMethod",
                              "storageKey": null
                            },
                            (v10/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AppointmentField",
                          "kind": "LinkedField",
                          "name": "fieldsData",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v10/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "value",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "amountPaid",
                          "storageKey": null
                        },
                        (v16/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createdBy",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Service",
                          "kind": "LinkedField",
                          "name": "service",
                          "plural": false,
                          "selections": [
                            (v9/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "minSlotsRequired",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Provider",
                          "kind": "LinkedField",
                          "name": "provider",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TimeSlot",
                          "kind": "LinkedField",
                          "name": "timeSlots",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            (v17/*: any*/),
                            (v18/*: any*/),
                            (v19/*: any*/),
                            (v11/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v17/*: any*/),
                        (v15/*: any*/),
                        (v18/*: any*/),
                        (v12/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ]
        },
        {
          "condition": "fetchTimeSlots",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterBy",
                  "variableName": "timeSlotsFilterBy"
                },
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "first"
                }
              ],
              "concreteType": "TimeSlotConnection",
              "kind": "LinkedField",
              "name": "timeSlots",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeSlotEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TimeSlot",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v19/*: any*/),
                        (v11/*: any*/),
                        (v17/*: any*/),
                        (v18/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recurringTimeSlotId",
                          "storageKey": null
                        },
                        (v13/*: any*/),
                        (v14/*: any*/),
                        (v16/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfcf291190b459984cf09b4314024455';

module.exports = node;

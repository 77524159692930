import {Form, Button, Divider, Header, Table, List, Icon, Segment, TextArea, Modal, Dropdown} from 'semantic-ui-react';
import styled from 'styled-components';
import {defaultsDeep, get, isEmpty} from 'lodash';
import graphql from 'babel-plugin-relay/macro';
import {createRefetchContainer} from 'react-relay';
import React from 'react';
import moment from 'moment';
import {withRelay} from '../../relay';
import RecurrenceOptionsView from './RecurrenceOptionsView';
import {
  UpdateAppointmentWithTimeSlotsMutation,
  BookTimeSlotsMutation,
  OfferAlternativeHoursMutation,
  ConfirmAppointmentMutation,
  BookRecurringTimeSlotsMutation,
  DeleteAppointmentMutation,
  DeleteAppointmentsMutation,
  MarkAppointmentAsPaidMutation,
} from '../../mutations';
import ServicesDropdown from './ServicesDropdown';
import ClientsDropdown from './ClientsDropdown';
import ProvidersDropdown from './ProvidersDropdown';
import MonthsDropdown from './MonthsDropdown';
import SelectedClientView from './SelectedClientView';
import SelectedServiceView from './SelectedServiceView';
import AppointmentPaymentMethodModal from './AppointmentPaymentMethodModal';
import SelectedProviderView from './SelectedProviderView';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './AppointmentModal.css';
import TimeSlotsCalendar from './TimeSlotsCalendar';
import './TimeSlotsCalendar.css';
import {withTranslation} from 'react-i18next';
import CustomLoader from '../../helpers/CustomLoader';
import AppointmentInfo from './AppointmentInfo';
import AppointmentFieldInput from './AppointmentFieldsInput';
import groupAppointmentsTimeSlots from '../../helpers/groupAppointmentsTimeSlots';

let isFetching = false;

let refetchVariables = {
  fetchTimeSlots: false,
  fetchAppointments: false,
  orderBy: [['startAt', 'DESC']],
  timeSlotsFilterBy: {
    date: {
      from: moment().format('YYYY-MM-DD'),
      to: moment().endOf('month').format('YYYY-MM-DD'),
    },
    serviceId: null,
  },
  first: 10000,
};

const ConfirmationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const AppointmentFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-top: 1px solid lightgray;
  flex: 1;
`;

const AppointmentField = styled.div`
  margin-top: 10px;
`;

const AppointmentFieldName = styled.div`
  font-weight: 400;
  font-size: 15px;
`;

const AppointmentFieldValue = styled.div`
  font-size: 14px;
`;

const AppointmentFieldInputs = styled.div`
  margin-top: 20px;
  width: 100%;
`;

class AppointmentModal extends React.Component {
  state = {
    selectedMonth: moment().month(),
    clientId: null,
    serviceId: null,
    providerId: null,
    // selectedTimeSlot: null,
    selectedTimeSlots: [],
    markAsPaidError: false,
    isConfirmModalOpen: false,
    isAppointmentRepeating: false,
    isDeleteButtonLoading: false,
    cancellationReason: null,
    fieldsData: {},
    untilDateForRecurringAppointment: null,
    isRecurringAppointmentOngoing: false,
    isThereOnlyOneProvider: false,
    isAppointmentPaymentMethodsModalOpen: false,
    placePaymentMethodId: null,
    formattedTimeSlots: null,
    isLoading: false,
    isAlternativeTimesClicked: false,
    alternativeTimes: [],
    step: 0,
  };

  constructor(props) {
    super(props);

    this.monthOptions = [];
    const numberOfMonhtsAhead = 12;
    for (let i = 0; i < numberOfMonhtsAhead; i++) {
      const date = moment().date(1).add(i, 'month');

      this.monthOptions.push({
        key: date.month(),
        text: date.format('MMMM').toLowerCase(),
        value: date.month(),
        fulldate: date.toISOString(),
      });
    }

    const isThereOnlyOneService = get(props, 'viewer.services.edges.length', 0) === 1 ? true : false;
    this.state = {...this.state, isThereOnlyOneService};

    this.timeSlots = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const {viewer} = props;

    if (
      viewer &&
      viewer.appointments &&
      get(viewer, 'appointments.edges[0].node.fieldsData', false) &&
      isEmpty(state.fieldsData)
    ) {
      const {fieldsData: rawFieldsData} = viewer.appointments.edges[0].node;
      const fieldsData = rawFieldsData.reduce((p, c) => {
        p[c.rowId] = c.value;
        return p;
      }, {});

      return {
        fieldsData,
      };
    }
    return null;
  }

  componentDidMount() {
    const {appointmentIds} = this.props;

    if (!appointmentIds || appointmentIds.length === 0) {
      // New appointment
      return;
    }

    const {appointments: _appointments, places} = this.props.viewer;
    if (!_appointments) {
      return;
    }

    const appointments = _appointments.edges.map(({node}) => node);
    if (appointments && appointments.length === 0) {
      return;
    }

    const _startAt = moment(appointments[0].startAt);

    // appointments is in the past or is opened from Notifications Sidebar
    if (_startAt.isBefore(moment()) || this.isEditingAppointment()) {
      const place = get(places, 'edges[0].node');

      // Calculate options only once on mount and store them in state
      const placePaymentMethodOptions =
        place &&
        place.paymentMethods.map((paymentMethod) => {
          return {
            key: paymentMethod.id,
            text: paymentMethod.name,
            value: paymentMethod.id,
          };
        });

      return this.setState({
        step: 1,
        isLoading: false,
        placePaymentMethodOptions,
      });
    }

    this.setState({isLoading: true});

    refetchVariables.timeSlotsFilterBy = {
      date: {
        from: moment(appointments[0].startAt).subtract(7, 'days').format('YYYY-MM-DD'),
        to: moment(appointments[0].startAt).endOf('month').format('YYYY-MM-DD'),
      },
      serviceId: appointments[0].serviceId,
      providerId: appointments[0].providerId,
      includeAll: false,
    };

    this.props.relay.refetch(refetchVariables, null, () => {
      this.handleDidMountRefetchCallback(appointments);
    });
  }

  handleDidMountRefetchCallback = (appointments) => {
    const {serviceId, providerId, timeSlots, clientId} = appointments[0];
    const startAt = moment(appointments[0].startAt);

    const decoratedTimeSlots = timeSlots.map((t) => ({
      ...t,
      providerId,
      clientId,
      serviceId,
    }));

    this.setState({
      selectedTimeSlots: decoratedTimeSlots,
      selectedMonth: startAt.month(),
      serviceId,
      providerId,
      clientId,
      isLoading: false,
    });
  };

  isModalActionClicked = (prevState) =>
    this.props.viewer.appointments &&
    this.props.viewer.appointments.edges.length &&
    prevState.step === 1 &&
    this.state.step === 0;

  componentDidUpdate(prevProps, prevState) {
    // TODO: Revise logic after merge
    // Appointment clicked from Calendar.js
    if (!prevProps.appointmentIds && this.props.appointmentIds && !this.props.viewer.appointments) {
      this.setState({isLoading: true});
      const {appointmentIds} = this.props;
      refetchVariables.fetchAppointments = true;
      refetchVariables.filterBy = {appointmentIds};

      // Refetch appointments
      this.props.relay.refetch(refetchVariables, null, () => {
        this.handleClickedAppointmentFromCalendarCallback();
      });
    }

    // Clicked action
    if (this.isModalActionClicked(prevState)) {
      this.setState({isLoading: true});
      const {appointments: _appointments} = this.props.viewer;
      const appointments = _appointments.edges.map(({node}) => node);
      const {providerId, clientId} = appointments[0];

      refetchVariables.timeSlotsFilterBy = {
        date: {
          from: moment(appointments[0].startAt).subtract(7, 'days').format('YYYY-MM-DD'),
          to: moment(appointments[0].startAt).endOf('month').format('YYYY-MM-DD'),
        },
        serviceId: appointments[0].serviceId,
        providerId,
        clientId,
        includeAll: false,
      };
      refetchVariables.fetchTimeSlots = true;

      this.props.relay.refetch(refetchVariables, null, () => {
        this.handleClickedModalActionRefetchCallback(appointments);
      });
    }

    const serviceId = this.state.serviceId;
    const servicesLength = get(this.props, 'viewer.services.edges.length', 0);
    if (serviceId === null && servicesLength === 1) {
      // set the serviceId if it's only one
      const serviceRowId = get(this.props, 'viewer.services.edges[0].node.rowId', null);
      this.setState({serviceId: serviceRowId});
    }

    if (serviceId && this.state.providerId === null) {
      const selectedService = this.props.viewer.services.edges.find(({node}) => node.rowId === serviceId).node;
      const providersLength = get(selectedService, 'providers.edges.length', 0);
      if (providersLength && providersLength === 1) {
        // set providerId if it's only one
        const providerRowId = get(selectedService, 'providers.edges[0].node.rowId', null);
        this.setState({providerId: providerRowId, isThereOnlyOneProvider: true}, this.refetchOnProviderChange);
      }
    }
  }

  handleClickedAppointmentFromCalendarCallback = () => {
    this.setState({isLoading: false});
    const {appointments: _appointments} = this.props.viewer;
    if (!_appointments) {
      return null;
    }

    const appointments = _appointments.edges.map(({node}) => node);
    const _startAt = moment(appointments[0].startAt);

    if (_startAt.isBefore(moment()) || this.isEditingAppointment()) {
      return this.setState({
        step: 1,
        isLoading: false,
      });
    }
  };

  handleClickedModalActionRefetchCallback = (appointments) => {
    const formattedTimeSlots = this.getFormattedTimeSlots();
    const startAt = moment(appointments[0].startAt);
    const {serviceId, providerId, timeSlots, clientId} = appointments[0];

    const decoratedTimeSlots = timeSlots.map((t) => ({
      ...t,
      providerId,
      clientId: clientId === null ? undefined : clientId,
      serviceId,
    }));

    this.setState(
      {
        selectedTimeSlots: this.state.isAlternativeTimesClicked ? [] : decoratedTimeSlots,
        selectedMonth: startAt.month(),
        serviceId,
        formattedTimeSlots,
        clientId: clientId === null ? undefined : clientId,
        providerId,
        isLoading: false,
      },
      () => {
        this.timeSlots.current &&
          this.state.selectedTimeSlots.length &&
          this.timeSlots.current.scrollToItem(
            this.timeSlots.current.getItemElementById(this.state.selectedTimeSlots[0].date, 'smooth', 'center'),
          );
      },
    );
  };

  isEditingAppointment = () => this.props.viewer.appointments && this.props.viewer.appointments.edges.length;

  handleClientChange = (i, data) => {
    const {value: clientId} = data;

    this.setState({clientId});

    const {serviceId, providerId} = this.state;
    if (!serviceId || !providerId) {
      return;
    }

    refetchVariables.timeSlotsFilterBy = defaultsDeep({clientId}, refetchVariables.timeSlotsFilterBy);
    refetchVariables.fetchTimeSlots = true;

    this.setState({isLoading: true});
    this.props.relay.refetch(refetchVariables, null, (error) => {
      if (error) {
        return this.setState({isLoading: false});
      }
      const formattedTimeSlots = this.getFormattedTimeSlots();
      return this.setState({formattedTimeSlots, isLoading: false});
    });
  };

  handleServiceChange = (e, data) => {
    const {value: serviceId} = data;

    this.setState({serviceId});
  };

  refetchOnProviderChange = () => {
    const {serviceId, providerId, clientId} = this.state;

    this.setState({isLoading: true});

    const now = moment();
    refetchVariables.timeSlotsFilterBy = defaultsDeep(
      {
        clientId,
        serviceId,
        providerId,
        date: {
          from: now.format('YYYY-MM-DD'),
          to: now.endOf('month').format('YYYY-MM-DD'),
        },
      },
      refetchVariables.timeSlotsFilterBy,
    );

    refetchVariables.fetchTimeSlots = true;

    this.props.relay.refetch(refetchVariables, null, (error) => {
      if (error) {
        return this.setState({isLoading: false});
      }
      const formattedTimeSlots = this.getFormattedTimeSlots();
      return this.setState({formattedTimeSlots, isLoading: false});
    });
  };

  handleProviderChange = (e, data) => {
    const {value: providerId} = data;

    this.setState({providerId: providerId}, this.refetchOnProviderChange);
  };

  handleMonthChange = (e, data) => {
    const {value, options} = data;
    this.setState({selectedMonth: value});

    const monthOption = options.find((option) => option.key === value);

    const from = moment(monthOption.fulldate).isBefore(moment())
      ? moment().format('YYYY-MM-DD')
      : moment(monthOption.fulldate).format('YYYY-MM-DD');
    const to = moment(from).endOf('month').format('YYYY-MM-DD');

    this.setState({isLoading: true});

    refetchVariables.timeSlotsFilterBy = defaultsDeep({date: {from, to}}, refetchVariables.timeSlotsFilterBy);

    this.props.relay.refetch(refetchVariables, null, (error) => {
      // TODO: Handle recurring time slots
      // TODO: Implement Pagination Controller

      const formattedTimeSlots = this.getFormattedTimeSlots(true);

      this.setState({isLoading: false, formattedTimeSlots});
    });
  };

  // TODO: Overfetch to avoid freezing
  handleLeftArrowClick = (firstVisibleDate) => {
    if (isFetching) {
      return;
    }

    const formattedLeftMostDate = moment(firstVisibleDate);
    const difference = formattedLeftMostDate.diff(moment(), 'days');

    if (difference > 0) {
      isFetching = true;

      const _refetchVariables = defaultsDeep(
        {
          timeSlotsFilterBy: {
            date: {
              from: moment(firstVisibleDate).subtract(8, 'days').format('YYYY-MM-DD'),
            },
          },
        },
        refetchVariables,
      );

      this.props.relay.refetch(
        _refetchVariables,
        null,
        (error) => {
          isFetching = false;
          const formattedTimeSlots = this.getFormattedTimeSlots();
          this.setState({formattedTimeSlots});

          if (error) {
            console.log('Error', error);
          }
        },
        {
          force: false,
        },
      );
    }
  };

  // TODO: Implement pagination
  // https://medium.com/entria/relay-modern-pagination-using-refetch-container-editing-a07c6b33ae4e
  handleRightArrowClick = (lastVisibleItemDate) => {
    if (isFetching) {
      return;
    }

    isFetching = true;
    // TODO: Fix this hack
    refetchVariables.timeSlotsFilterBy = {
      ...refetchVariables.timeSlotsFilterBy,
      date: {
        to: moment(lastVisibleItemDate).add(14, 'days').format('YYYY-MM-DD'),
        from: refetchVariables.timeSlotsFilterBy.date.from,
      },
    };

    // TODO
    // renderVariables = {...}

    this.props.relay.refetch(
      refetchVariables,
      null,
      (error) => {
        if (error) {
          console.log('Error', error);
        }
        isFetching = false;
        const formattedTimeSlots = this.getFormattedTimeSlots();
        this.setState({formattedTimeSlots});
      },
      {
        force: false,
      },
    );
  };

  handleTimeSlotClick = (timeSlot) => {
    const {alternativeTimes, isAlternativeTimesClicked} = this.state;

    if (isAlternativeTimesClicked) {
      const date = moment(timeSlot.date);
      const formattedAlternativeTime = {
        ...timeSlot,
        startAt: moment(`${date.format('YYYY-MM-DD')} ${timeSlot.startAt}`).toISOString(),
        endAt: moment(`${date.format('YYYY-MM-DD')} ${timeSlot.endAt}`).toISOString(),
      };

      // if we click again time slot - deactivate it.
      if (alternativeTimes.some((slot) => slot.id === timeSlot.id)) {
        this.setState((previousState) => {
          const filteredTimeSlots = previousState.alternativeTimes.filter((_timeSlot) => _timeSlot.id !== timeSlot.id);
          return {
            alternativeTimes: [...filteredTimeSlots],
          };
        });
      } else {
        this.setState((previousState) => ({
          alternativeTimes: [...previousState.alternativeTimes, formattedAlternativeTime],
        }));
      }
    } else {
      // this.setState({selectedTimeSlot: timeSlot});
      this.setState((prevState) => {
        if (prevState.selectedTimeSlots.some((_timeSlot) => _timeSlot.id === timeSlot.id)) {
          const filteredTimeSlots = prevState.selectedTimeSlots.filter((_timeSlot) => _timeSlot.id !== timeSlot.id);
          return {
            selectedTimeSlots: [...filteredTimeSlots],
          };
        } else {
          return {
            selectedTimeSlots: [...this.state.selectedTimeSlots, timeSlot],
          };
        }
      });
    }
  };

  handleAlternativeHoursClick = () => {
    this.setState({isAlternativeTimesClicked: true, step: 0});
  };

  handleNextStep = () => {
    if (this.state.step === 0) {
      this.setState({step: 1});
    } else if (this.state.step === 2) {
      this.handleUpdateAppointment();
    } else {
      this.handleAddAppointment();
    }
  };

  handlePrevStep = () => {
    const {selectedTimeSlots} = this.state;

    this.setState({step: 0}, () => {
      selectedTimeSlots.length &&
        this.timeSlots.current &&
        this.timeSlots.current.scrollToItem(
          this.timeSlots.current.getItemElementById(selectedTimeSlots[0].date, 'smooth', 'center'),
        );
    });
  };

  handleUpdateAppointment = () => {
    this.setState({isLoading: true});

    const {appointments: _appointments} = this.props.viewer;
    const appointments = _appointments && _appointments.edges.map(({node}) => node);

    const {selectedTimeSlots, isAlternativeTimesClicked, fieldsData, alternativeTimes} = this.state;

    const onSuccess = (data) => {
      this.setState({
        isLoading: false,
        serviceId: null,
        step: 0,
        clientId: null,
        providerId: null,
        selectedTimeSlots: [],
        isAlternativeTimesClicked: false,
        alternativeTimes: [],
      });

      refetchVariables.fetchTimeSlots = false;
      refetchVariables.fetchAppointments = false;

      // NOTE: fetchPolicy added in order to avoid network request for changing include directive value
      this.props.relay.refetch(refetchVariables, null, null, {
        fetchPolicy: 'store-or-network',
      });

      this.props.onClose();
    };

    const onFailure = (errors) => {
      const errorMessage = get(errors, '[0].message') || JSON.stringify(errors);
      window.alert(errorMessage);

      this.setState({isLoading: false});
    };

    if (isAlternativeTimesClicked) {
      const input = {
        id: appointments[0].id,
        alternativeTimes,
      };

      return OfferAlternativeHoursMutation({input}, onSuccess, onFailure);
    }

    // const _date = moment(date);

    // const input = {
    //   id: appointments[0].id,
    //   status: 'Confirmed',
    //   clientId,
    //   serviceId,
    //   providerId,
    //   startAt: moment(`${_date.format('YYYY-MM-DD')} ${startAt}`).toISOString(),
    //   endAt: moment(`${_date.format('YYYY-MM-DD')} ${endAt}`).toISOString(),
    // };

    const input = {
      id: appointments[0].id,
      timeSlots: selectedTimeSlots,
      fieldsData,
    };

    return UpdateAppointmentWithTimeSlotsMutation({input}, onSuccess, onFailure);

    // UpdateAppointmentMutation({input}, onSuccess, onFailure);
  };

  handleConfirmAppointment = () => {
    this.setState({isLoading: true});

    const {appointments: _appointments} = this.props.viewer;
    const appointments = _appointments && _appointments.edges.map(({node}) => node);

    const input = {
      id: appointments[0].id,
    };

    const onSuccess = (data) => {
      this.setState({
        isLoading: false,
        serviceId: null,
        step: 0,
        clientId: null,
        appointmentIds: null,
        providerId: null,
        selectedTimeSlot: null,
        appointments: null,
        isAlternativeTimesClicked: false,
        alternativeTimes: [],
      });

      refetchVariables.fetchTimeSlots = false;
      refetchVariables.fetchAppointments = false;

      // NOTE: fetchPolicy added in order to avoid network request for changing include directive value
      this.props.relay.refetch(refetchVariables, null, null, {
        fetchPolicy: 'store-or-network',
      });
      this.props.onClose();
    };

    const onFailure = (errors) => {
      console.log('Failure', errors);
      this.setState({isLoading: false});
    };

    ConfirmAppointmentMutation({input}, onSuccess, onFailure);
  };

  handleAddAppointment = () => {
    this.setState({isLoading: true});

    const {viewer} = this.props;
    const {selectedTimeSlots, fieldsData, isAppointmentRepeating} = this.state;

    const input = {timeSlots: selectedTimeSlots};

    if (!isEmpty(fieldsData)) {
      input.fieldsData = fieldsData;
    }

    const onSuccess = (data) => {
      this.setState({
        isLoading: false,
        serviceId: null,
        step: 0,
        clientId: null,
        providerId: null,
        selectedTimeSlot: null,
        selectedTimeSlots: [],
        appointments: null,
        isAlternativeTimesClicked: false,
        alternativeTimes: [],
      });

      refetchVariables.fetchTimeSlots = false;

      // NOTE: fetchPolicy added in order to avoid network request for changing include directive value
      this.props.relay.refetch(refetchVariables, null, null, {
        fetchPolicy: 'store-or-network',
      });

      this.props.onClose();
    };

    const onFailure = (errors) => {
      const errorMessage = get(errors, '[0].message') || JSON.stringify(errors);
      window.alert(errorMessage);

      this.setState({isLoading: false});
    };

    const connectionName = 'Calendar_appointments';

    if (isAppointmentRepeating) {
      input.recurrenceOptions = {
        freq: this.state.recurringFrequency,
        until: this.state.untilDateForRecurringAppointment,
      };

      return BookRecurringTimeSlotsMutation({input, connectionName, viewerId: viewer.id}, onSuccess, onFailure);
    }

    BookTimeSlotsMutation({input, connectionName, viewerId: viewer.id}, onSuccess, onFailure);
  };

  handleDeleteAppointment = (e, appointmentId) => {
    this.setState({isDeleteButtonLoading: true, isLoading: true});

    const {viewer} = this.props;
    const {appointments: _appointments} = viewer;
    const appointmentIds = _appointments && _appointments.edges.map(({node}) => node.id);

    const input = {};

    appointmentId ? (input.id = appointmentId) : (input.ids = appointmentIds);

    if (this.state.cancellationReason && this.state.cancellationReason.trim() !== '') {
      input.cancellationReason = this.state.cancellationReason;
    }

    const onSuccess = (data) => {
      // Remaining attendees in recurring appointment
      if (appointmentId && appointmentIds.length > 1) {
        this.setState({isRemoveAttendeeModalOpen: false, isDeleteButtonLoading: false});
      } else {
        this.setState({
          step: 0,
          appointments: null,
          appointmentIds: null,
          selectedTimeSlot: null,
          providerId: null,
          serviceId: null,
          clientId: null,
          isLoading: false,
          isRemoveAttendeeModalOpen: false,
          isDeleteButtonLoading: false,
          isConfirmModalOpen: false,
          isAlternativeTimesClicked: false,
          alternativeTimes: [],
        });

        refetchVariables.fetchTimeSlots = false;
        refetchVariables.fetchAppointments = false;

        // NOTE: fetchPolicy added in order to avoid network request for changing include directive value
        this.props.relay.refetch(refetchVariables, null, null, {
          fetchPolicy: 'store-or-network',
        });
        this.props.onClose();
      }
    };

    const onFailure = (errors) => {
      this.setState({isDeleteButtonLoading: false});
      console.log('Failure', errors);
    };

    const connectionNames = ['Calendar_appointments', 'AppointmentModal_appointments'];
    const payload = {
      input,
      connectionNames,
      viewerId: viewer.id,
    };

    input.id
      ? DeleteAppointmentMutation(payload, onSuccess, onFailure)
      : DeleteAppointmentsMutation(payload, onSuccess, onFailure);
  };

  handleDeselectClient = () =>
    this.setState({clientId: null, selectedTimeSlots: [], selectedMonth: moment().month(), fetchTimeSlots: false});

  handleDeselectService = () =>
    this.setState({
      serviceId: null,
      providerId: null,
      selectedMonth: moment().month(),
      selectedTimeSlots: [],
      fetchTimeSlots: false,
    });

  handleDeselectProvider = () => {
    refetchVariables.fetchTimeSlots = false;

    this.setState(
      {
        providerId: null,
        selectedMonth: moment().month(),
        formattedTimeSlots: null,
        selectedTimeSlots: [],
        fetchTimeSlots: false,
      },
      () => this.props.relay.refetch(refetchVariables),
    );
  };

  handleUpdate = (date) => {
    if (!date) {
      return null;
    }

    const {selectedMonth} = this.state;

    const selectedMonthDate = moment().month(selectedMonth);
    const newScrollDate = moment(date);

    if (selectedMonthDate.month() !== newScrollDate.month()) {
      this.setState({selectedMonth: newScrollDate.month()});
    }
  };

  handleClose = () => {
    this.setState({
      step: 0,
      appointments: null,
      appointmentIds: null,
      selectedTimeSlot: null,
      selectedTimeSlots: [],
      formattedTimeSlots: null,
      providerId: null,
      serviceId: null,
      clientId: null,
      isLoading: false,
      isAlternativeTimesClicked: false,
      alternativeTimes: [],
    });

    refetchVariables.fetchTimeSlots = false;
    refetchVariables.fetchAppointments = false;
    /* TODO: Improve control of isLoading value since after handleClose,
    CDU is called and isLoading is set to true on closed modal
    /*/

    // NOTE: fetchPolicy added in order to avoid network request for changing include directive value
    this.props.relay.refetch(refetchVariables, null, () => this.setState({isLoading: false}), {
      fetchPolicy: 'store-or-network',
    });

    this.props.onClose();
  };

  hasUnsavedChanges = () => {
    const {appointments: _appointments} = this.props.viewer;
    const {selectedTimeSlots, isAlternativeTimesClicked, alternativeTimes} = this.state;

    if (isAlternativeTimesClicked) {
      return alternativeTimes.length !== 0;
    }

    const appointments = _appointments && _appointments.edges.map(({node}) => node);
    // TODO: Revise logic after merge
    if (this.isEditingAppointment() && appointments) {
      return !isEmpty(
        selectedTimeSlots.filter((timeSlot) =>
          appointments[0].timeSlots.every((_timeSlot) => _timeSlot.id !== timeSlot.id),
        ),
      );
    }
    return true;
  };

  handleUntilDateSelected = (untilDate) => {
    this.setState({untilDateForRecurringAppointment: untilDate});

    if (this.state.isRecurringAppointmentOngoing) {
      this.setState({isRecurringAppointmentOngoing: false});
    }
  };

  handleRecurringFrequencyChange = (e, data) => {
    this.setState({recurringFrequency: data.value});
  };

  handleOngoingCheckboxClick = (e, {checked}) => {
    this.setState({isRecurringAppointmentOngoing: checked});

    if (checked && this.state.untilDateForRecurringAppointment) {
      this.setState({untilDateForRecurringAppointment: null});
    }
  };

  handleMarkAsPaidAppointment = () => {
    const {placePaymentMethodId} = this.state;
    if (!placePaymentMethodId) {
      return null;
    }

    const appointment = this.props.viewer.appointments.edges[0].node;

    const payload = {
      input: {
        appointmentId: appointment.id,
        placePaymentMethodId,
      },
    };

    const onFailure = (error) => {
      if (error) {
        return this.setState({markAsPaidError: true});
      }
    };

    const onSuccess = (data) => {
      this.setState({isAppointmentPaymentMethodsModalOpen: false, markAsPaidError: false});
    };

    MarkAppointmentAsPaidMutation(payload, onSuccess, onFailure);
  };

  handleAppointmentFieldInput = (value, rowId) => {
    this.setState((prevState) => {
      const fieldsData = {...prevState.fieldsData};
      fieldsData[rowId] = value;
      return {fieldsData};
    });
  };

  renderConfirmation = () => {
    const {serviceId, clientId, selectedTimeSlots, providerId, alternativeTimes} = this.state;
    const {viewer} = this.props;
    const {clients, appointments: _appointments, services} = viewer;
    const appointments = _appointments && _appointments.edges.map(({node}) => node);
    const payments = get(appointments, '[0].payments');
    const createdBy = get(appointments, '[0].createdBy');
    const fieldsData = get(appointments, '[0].fieldsData');
    const cancellationReason = get(appointments, '[0].cancellationReason');

    let client, service, provider, timeSlots;
    if (alternativeTimes.length) {
      timeSlots = alternativeTimes.map((time) => ({
        date: moment(time.startAt).format('YYYY-MM-DD'),
        startAt: moment(time.startAt, 'kk:mm').format('k:mm'),
        endAt: moment(time.endAt, 'kk:mm').format('k:mm'),
      }));

      client = appointments[0].client || {};
      service = appointments[0].service || {};
      provider = appointments[0].provider || {};
    } else if (selectedTimeSlots.length) {
      timeSlots = selectedTimeSlots.map((slot) => ({
        date: slot.date,
        startAt: moment(slot.startAt, 'kk:mm').format('k:mm'),
        endAt: moment(slot.endAt, 'kk:mm').format('k:mm'),
      }));

      client = clientId ? clients.edges.find(({node}) => node.rowId === clientId).node : {};
      service = serviceId ? services.edges.find(({node}) => node.rowId === serviceId).node : {};
      provider =
        providerId && !isEmpty(service) ? service.providers.edges.find(({node}) => node.rowId === providerId).node : {};
    } else if (this.isEditingAppointment()) {
      const formattedDate = moment(appointments[0].startAt).format('YYYY-MM-DD');
      const formattedStartAt = moment(appointments[0].startAt).format('k:mm');
      const formattedEndAt = moment(appointments[0].endAt).format('k:mm');

      timeSlots = [{date: formattedDate, startAt: formattedStartAt, endAt: formattedEndAt}];

      client = appointments[0].client || {};
      service = appointments[0].service || {};
      provider = appointments[0].provider || {};
    }

    const {appointmentFields} = service;

    return (
      <ConfirmationContainer>
        <Row>
          <AppointmentInfo
            timeSlots={timeSlots}
            client={client}
            service={service}
            provider={provider}
            payments={payments}
            createdBy={createdBy}
            cancellationReason={cancellationReason}
          />
          {!this.isEditingAppointment() && (
            <RecurrenceOptionsView
              isAppointmentRepeating={this.state.isAppointmentRepeating}
              selectedRecurrenceFrequency={this.state.recurringFrequency}
              isRecurringAppointmentOngoing={this.state.isRecurringAppointmentOngoing}
              onRecurringFrequencyChange={this.handleRecurringFrequencyChange}
              onUntilDateSelected={this.handleUntilDateSelected}
              onRepeatingCheckboxToggle={this.handleRepeatingCheckboxToggle}
              onOngoingCheckboxClick={this.handleOngoingCheckboxClick}
            />
          )}
        </Row>
        <Row>
          {appointmentFields && (
            <AppointmentFieldInputs>
              {appointmentFields.map((field) => (
                <AppointmentFieldInput
                  fieldData={field}
                  key={field.rowId}
                  value={this.state.fieldsData[field.rowId] || ''}
                  onChange={this.handleAppointmentFieldInput}
                />
              ))}
            </AppointmentFieldInputs>
          )}
          {this.isEditingAppointment() && this.state.step === 1 && fieldsData && (
            <AppointmentFieldsContainer>
              {fieldsData.map((fieldData) => (
                <AppointmentField key={fieldData.id}>
                  <AppointmentFieldName>{fieldData.name}</AppointmentFieldName>
                  <AppointmentFieldValue>{fieldData.value || '-'}</AppointmentFieldValue>
                </AppointmentField>
              ))}
            </AppointmentFieldsContainer>
          )}
        </Row>
      </ConfirmationContainer>
    );
  };

  handleRepeatingCheckboxToggle = (e, {checked}) => {
    this.setState({isAppointmentRepeating: checked});
  };

  undoSelectedAlternativeHours = () =>
    this.setState({
      isAlternativeTimesClicked: false,
      alternativeTimes: [],
    });

  renderHeader = () => {
    const {t} = this.props;
    const {step, isAlternativeTimesClicked} = this.state;
    const {appointments: _appointments} = this.props.viewer;
    const appointments = _appointments && _appointments.edges.map(({node}) => node);

    const isAppointmentInThePast = this.isEditingAppointment() && moment(appointments[0].startAt).isBefore(moment());

    const title = isAlternativeTimesClicked
      ? `${t('actions.offer_alternative_hours')}`
      : this.isEditingAppointment()
      ? isAppointmentInThePast || appointments[0].isRecurring || step === 1
        ? `${t('common.appointment')} - ${t(
            `common.${appointments[0].status.split(' ').join('_').toLowerCase()}`,
          ).toUpperCase()}`
        : `${t('actions.edit_appointment')}`
      : `${t('actions.add_appointment')}`;

    return <Modal.Header>{title}</Modal.Header>;
  };

  isItemActive = (timeSlotId) => {
    return this.state.selectedTimeSlots.length && this.state.selectedTimeSlots.some((slot) => slot.id === timeSlotId);
  };

  renderRecurringTimeSlots = (timeSlots) => {
    const {t} = this.props;
    if (isEmpty(timeSlots)) {
      return (
        <div className="menu-item">
          <Header as="h5"> {t('common.no_time_slots')}</Header>
        </div>
      );
    }

    const items = Object.keys(timeSlots).map((date) => {
      const _timeSlots = timeSlots[date];
      const _date = moment(date);

      return (
        <div className="menu-item" key={date}>
          <div>
            <Header as="h5">{` ${_date.format('ddd')}  ${_date.format('DD')}`}</Header>
          </div>
          <List selection>
            {_timeSlots.map((timeSlot) => (
              <List.Item
                active={this.isItemActive(timeSlot.id)}
                className="list-item"
                key={timeSlot.id}
                onClick={() => this.handleTimeSlotClick(timeSlot)}
              >
                <Segment>
                  {timeSlot.startAt} - {timeSlot.endAt}
                </Segment>
              </List.Item>
            ))}
          </List>
        </div>
      );
    });

    return items;
  };

  renderServicesByCategories = () => {
    const {t} = this.props;

    const servicesByCategories = this.props.viewer.services.edges.reduce((prev, {node: current}) => {
      if (!prev[current.category.id]) {
        prev[current.category.id] = {name: current.category.name, services: []};
      }

      prev[current.category.id].services.push(current);
      return prev;
    }, {});

    return (
      <Dropdown placeholder={t('titles.select_service')} selection fluid search={true}>
        <Dropdown.Menu>
          {Object.keys(servicesByCategories).map((key) => {
            const category = servicesByCategories[key];
            return (
              // React.Fragment can accept key prop in contrast to <>
              <React.Fragment key={key}>
                <Dropdown.Header content={category.name} />
                <Dropdown.Divider />
                {category.services.map((service) => (
                  <Dropdown.Item onClick={this.handleServiceChange} value={service.rowId} key={service.rowId}>
                    {service.title}
                  </Dropdown.Item>
                ))}
                <div style={{height: '20px'}} />
              </React.Fragment>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderAppointmentContent = () => {
    const {
      alternativeTimes,
      clientId,
      isAlternativeTimesClicked,
      isLoading,
      selectedTimeSlots,
      formattedTimeSlots,
      serviceId,
      providerId,
      isThereOnlyOneProvider,
      isThereOnlyOneService,
      step,
    } = this.state;

    const {viewer, t} = this.props;

    const {clients, places, services} = viewer;

    const hasCategories = get(places, 'edges[0].node.settings.showServiceCategories') || false;

    const selectedClient = clientId && clients.edges.find(({node}) => node.rowId === clientId).node;
    const selectedService = serviceId && services.edges.find(({node}) => node.rowId === serviceId).node;
    const selectedProvider =
      providerId && selectedService && selectedService.providers.edges.find(({node}) => node.rowId === providerId).node;

    return (
      <div>
        {isLoading ? (
          <CustomLoader t={t} />
        ) : (
          <div>
            {step === 0 && (
              <div>
                {!isAlternativeTimesClicked && (
                  <div className="details-container">
                    <div className="client-container">
                      {clientId !== null ? (
                        <SelectedClientView
                          client={selectedClient}
                          t={t}
                          onDeselectClient={this.handleDeselectClient}
                        />
                      ) : (
                        <ClientsDropdown clients={clients} t={t} onClientChange={this.handleClientChange} />
                      )}
                    </div>
                    <div className="service-container">
                      {serviceId ? (
                        <SelectedServiceView
                          service={selectedService}
                          isDeselectButtonDisabled={isThereOnlyOneService}
                          t={t}
                          onDeselectService={this.handleDeselectService}
                        />
                      ) : hasCategories ? (
                        this.renderServicesByCategories()
                      ) : (
                        <ServicesDropdown t={t} services={services} onServiceChange={this.handleServiceChange} />
                      )}
                    </div>
                    <div className="service-container">
                      {providerId ? (
                        <SelectedProviderView
                          provider={selectedProvider}
                          isDeselectButtonDisabled={isThereOnlyOneProvider}
                          onDeselectProvider={this.handleDeselectProvider}
                        />
                      ) : (
                        <ProvidersDropdown
                          services={services}
                          t={t}
                          serviceId={this.state.serviceId}
                          onProviderChange={this.handleProviderChange}
                        />
                      )}
                    </div>
                  </div>
                )}
                {formattedTimeSlots && serviceId && clientId !== null && providerId && (
                  <div className="timeslots-container">
                    {!isAlternativeTimesClicked && <Divider />}
                    <MonthsDropdown
                      t={t}
                      selectedMonth={this.state.selectedMonth}
                      options={this.monthOptions}
                      onMonthChange={this.handleMonthChange}
                    />
                    {selectedService.isRecurring ? (
                      this.renderRecurringTimeSlots(formattedTimeSlots)
                    ) : (
                      <TimeSlotsCalendar
                        forwardedRef={this.timeSlots}
                        timeSlots={formattedTimeSlots}
                        onFetchTimeSlotsForward={this.handleRightArrowClick}
                        onFetchTimeSlotsBackward={this.handleLeftArrowClick}
                        onUpdate={this.handleUpdate}
                        isFetching={isFetching}
                        onTimeSlotClick={this.handleTimeSlotClick}
                        selectedTimeSlots={isAlternativeTimesClicked ? alternativeTimes : selectedTimeSlots}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {(step === 1 || step === 2) && this.renderConfirmation()}
          </div>
        )}
      </div>
    );
  };

  getFormattedTimeSlots = (isFetchingFromMonthsDropdown) => {
    const {timeSlots, appointments: _appointments} = this.props.viewer;
    const formattedTimeSlots =
      timeSlots &&
      timeSlots.edges.reduce((p, {node}) => {
        if (!p[node.date]) {
          p[node.date] = [];
        }

        p[node.date].push(node);
        return p;
      }, {});

    if (_appointments && !this.state.isAlternativeTimesClicked) {
      const appointments = _appointments.edges.map(({node}) => node);
      const {timeSlots: appointmentTimeSlots} = appointments[0];

      // timeslots of appointment can be only from one month;
      const timeSlotsMonth = moment(appointmentTimeSlots[0].date).month();

      if (isFetchingFromMonthsDropdown && timeSlotsMonth !== this.state.selectedMonth) {
        return formattedTimeSlots;
      }

      // add appointment timeslots only if they are in the selected month
      const date = moment(appointments[0].startAt).format('YYYY-MM-DD');
      if (formattedTimeSlots && !formattedTimeSlots[date]) {
        formattedTimeSlots[date] = [];
      }

      formattedTimeSlots[date] = [...formattedTimeSlots[date], ...appointmentTimeSlots];
      formattedTimeSlots[date].sort((a, b) => moment(a.startAt, 'HH:mm').unix() - moment(b.startAt, 'HH:mm').unix());
    }
    return formattedTimeSlots;
  };

  isRecurringAppointmentActive = (appointment) => {
    const startAt = moment(appointment.startAt);
    return startAt.isAfter(moment());
  };

  handleOpenRemoveAttendeeModal = () => {
    this.setState({isRemoveAttendeeModalOpen: true});
  };

  renderReccurringAppointmentContent = () => {
    const {t} = this.props;
    const {appointments: _appointments} = this.props.viewer;

    const appointments = _appointments && _appointments.edges.map(({node}) => node);

    return (
      <div>
        <Header>
          {t('titles.list_attendees')} ({appointments.length})
        </Header>
        <Table basic="very" celled style={{marginTop: 20, borderColor: 'green'}}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>{t('common.name')}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.isEditingAppointment() &&
              appointments.map(
                (appointment, index) =>
                  appointment.client && (
                    <Table.Row key={index}>
                      <Table.Cell collapsing>{index + 1}</Table.Cell>
                      <Table.Cell>{appointment.client.name}</Table.Cell>
                      <Table.Cell collapsing>
                        <div>
                          <Modal
                            open={this.state.isRemoveAttendeeModalOpen}
                            trigger={
                              <Button
                                icon="close"
                                size="mini"
                                circular
                                disabled={!this.isRecurringAppointmentActive(appointment)}
                                onClick={() => this.setState({isRemoveAttendeeModalOpen: true})}
                              />
                            }
                            onClose={() => this.setState({isRemoveAttendeeModalOpen: false})}
                          >
                            <Header content={t('actions.confirm')} />
                            <Modal.Content>
                              {t(`common.remove_client_from_appointment`, {name: appointment.client.name})}
                            </Modal.Content>
                            <Modal.Actions>
                              <Button onClick={() => this.setState({isRemoveAttendeeModalOpen: false})} color="green">
                                <Icon name="remove" /> {t('common.no')}
                              </Button>
                              <Button
                                onClick={(e) => this.handleDeleteAppointment(e, appointment.id)}
                                loading={this.state.isLoading}
                                color="red"
                              >
                                <Icon name="checkmark" /> {t('common.yes')}
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ),
              )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  handleAppointmentPaymentMethodSelect = (e, {value}) => {
    this.setState({placePaymentMethodId: value});
  };

  handleCancellationReasonInput = (e, {value}) => {
    this.setState({cancellationReason: value});
  };

  isConfirmButtonDisabled = () => {
    const {
      isAppointmentRepeating,
      recurringFrequency,
      untilDateForRecurringAppointment,
      isRecurringAppointmentOngoing,
    } = this.state;

    if (!isAppointmentRepeating) {
      return false;
    }

    return !(
      isAppointmentRepeating &&
      recurringFrequency &&
      (untilDateForRecurringAppointment || isRecurringAppointmentOngoing)
    );
  };

  areAlternativeTimesValid = (appointments) => {
    const {alternativeTimes} = this.state;
    if (isEmpty(alternativeTimes)) {
      return false;
    }

    const {service} = appointments[0];
    const {minSlotsRequired} = service;

    if (!minSlotsRequired) {
      return true;
    }

    if (alternativeTimes.length < minSlotsRequired) {
      return false;
    }

    const timeSlotsArray = groupAppointmentsTimeSlots(alternativeTimes);

    if (isEmpty(timeSlotsArray)) {
      return false;
    }

    return timeSlotsArray.every((timeSlotArray) => timeSlotArray.length >= minSlotsRequired);
  };

  renderModalActions = () => {
    const {t} = this.props;

    const {appointments: _appointments} = this.props.viewer;
    const appointments = _appointments && _appointments.edges.map(({node}) => node);

    const {step, isConfirmModalOpen, placePaymentMethodOptions, isLoading, selectedTimeSlots} = this.state;

    const isRecurring = appointments && appointments.length && appointments[0].isRecurring;
    const hideNextButton = this.isEditingAppointment() && isRecurring;
    const isPaid = Number(get(appointments, '[0].amountPaid')) > 0;
    const isAppointmentCanceled = get(appointments, '[0].status') === 'Canceled';

    const status = this.isEditingAppointment() && appointments[0].status;
    if (this.isEditingAppointment() && step === 1) {
      return (
        <div style={{flexDirection: 'row'}}>
          <div className="header-buttons">
            {status === 'Pending' && (
              <Button color="green" onClick={this.handleConfirmAppointment}>
                {t('actions.confirm')}
              </Button>
            )}
            {!isEmpty(placePaymentMethodOptions) && (
              <Button
                disabled={isPaid}
                color="green"
                onClick={() => this.setState({isAppointmentPaymentMethodsModalOpen: true})}
              >
                {t('common.mark_appointment_as_paid')}
              </Button>
            )}

            {!isRecurring && ( // Note: Hide Modify button when service is recurring
              <Button primary onClick={() => this.setState({step: 0})}>
                {t('actions.modify')}
              </Button>
            )}
            {!isRecurring && (
              <Button onClick={this.handleAlternativeHoursClick}>{t('actions.offer_alternative_hours')}</Button>
            )}
            {!isAppointmentCanceled && (
              <Button color="red" onClick={() => this.setState({isConfirmModalOpen: true})}>
                {t('actions.delete')}
              </Button>
            )}
            <Modal open={isConfirmModalOpen} onClose={() => this.setState({isConfirmModalOpen: false})}>
              <Modal.Content>
                <p>{t('actions.are_you_sure_you_want_to_cancel')}</p>
                <Form>
                  <TextArea
                    maxLength="120"
                    style={{minHeight: 50, maxHeight: 80}}
                    placeholder={t('common.give_reason')}
                    onChange={this.handleCancellationReasonInput}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({isConfirmModalOpen: false})}>{t('common.no')}</Button>
                <Button
                  primary
                  loading={this.state.isDeleteButtonLoading}
                  onClick={(e) =>
                    isRecurring ? this.handleDeleteAppointment() : this.handleDeleteAppointment(e, appointments[0].id)
                  }
                >
                  {t('common.yes')}
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      );
    }

    if (this.isEditingAppointment() && step === 0) {
      return (
        <div>
          {!isLoading && (selectedTimeSlots.length || this.state.alternativeTimes.length) && !hideNextButton && (
            <Button
              className="next-button"
              fluid
              color={'blue'}
              disabled={
                step === 1
                  ? this.isConfirmButtonDisabled()
                  : this.state.isAlternativeTimesClicked && !this.areAlternativeTimesValid(appointments)
              }
              onClick={() => this.setState({step: 2})}
            >
              {`${t('common.next')}`}
            </Button>
          )}
        </div>
      );
    }

    if (!this.isEditingAppointment() || step === 2) {
      return (
        <div style={{flexDirection: 'row'}}>
          {!isLoading && (selectedTimeSlots.length > 0 || this.state.isAlternativeTimesClicked) && !hideNextButton && (
            <Button.Group widths="2">
              {(step === 1 || step === 2) && (
                <Button className="prev-button" onClick={this.handlePrevStep}>
                  {t('common.back')}
                </Button>
              )}
              <Button
                className="next-button"
                fluid
                color={step === 0 ? 'blue' : 'green'}
                disabled={step === 1 && this.isConfirmButtonDisabled()}
                onClick={this.handleNextStep}
              >
                {step === 0 ? `${t('common.next')}` : `${t('actions.confirm')}`}
              </Button>
            </Button.Group>
          )}
        </div>
      );
    }
  };

  render() {
    const {isOpen, t} = this.props;

    const {appointments: _appointments} = this.props.viewer;
    const {clientId, serviceId, step, isAppointmentPaymentMethodsModalOpen, placePaymentMethodId} = this.state;

    let appointments = _appointments ? _appointments.edges.map(({node}) => node) : null;

    return (
      <>
        <Modal className="modal-container" onClose={this.handleClose} open={isOpen}>
          {this.renderHeader()}
          <Modal.Content
            className={
              step === 0 || (appointments && appointments.length && appointments[0].isRecurring && 'full-height')
            }
            scrolling={!!(step === 0 && serviceId && clientId !== null)}
          >
            {this.isEditingAppointment() && appointments[0].isRecurring
              ? this.renderReccurringAppointmentContent()
              : this.renderAppointmentContent()}
          </Modal.Content>
          {<Modal.Actions>{this.renderModalActions()}</Modal.Actions>}
          {isAppointmentPaymentMethodsModalOpen && (
            <AppointmentPaymentMethodModal
              isAppointmentPaymentMethodsModalOpen={isAppointmentPaymentMethodsModalOpen}
              t={t}
              error={this.state.markAsPaidError}
              handleModalClose={() => this.setState({isAppointmentPaymentMethodsModalOpen: false})}
              placePaymentMethodOptions={this.state.placePaymentMethodOptions}
              isConfirmButtonDisabled={!placePaymentMethodId ? true : false}
              onAppointmentPaymentMethodSelect={this.handleAppointmentPaymentMethodSelect}
              onMarkAppointmentAsPaid={this.handleMarkAsPaidAppointment}
            />
          )}
        </Modal>
      </>
    );
  }
}

const AppointmentModalQuery = graphql`
  query AppointmentModalRefetchQuery(
    $fetchTimeSlots: Boolean!
    $timeSlotsFilterBy: ProviderTimeSlotFilterInput
    $fetchAppointments: Boolean!
    $first: Int
    $appointmentId: ID
    $orderBy: [[String]]
    $filterBy: ProviderAppointmentFilterInput
  ) {
    viewer {
      ...AppointmentModal_viewer
        @arguments(
          fetchTimeSlots: $fetchTimeSlots
          fetchAppointments: $fetchAppointments
          timeSlotsFilterBy: $timeSlotsFilterBy
          first: $first
          filterBy: $filterBy
          appointmentId: $appointmentId
          orderBy: $orderBy
        )
    }
  }
`;

const AppointmentModalContainer = createRefetchContainer(
  withTranslation()(AppointmentModal),
  {
    viewer: graphql`
      fragment AppointmentModal_viewer on User
      @argumentDefinitions(
        fetchTimeSlots: {type: "Boolean!", defaultValue: false}
        fetchAppointments: {type: "Boolean!", defaultValue: false}
        timeSlotsFilterBy: {type: "ProviderTimeSlotFilterInput"}
        first: {type: "Int"}
        orderBy: {type: "[[String]]"}
        appointmentId: {type: "ID"}
        filterBy: {type: "ProviderAppointmentFilterInput"}
      ) {
        __typename
        ... on Provider {
          id
          appointments(filterBy: $filterBy, orderBy: $orderBy, first: 10000)
            @include(if: $fetchAppointments)
            @connection(key: "AppointmentModal_appointments", filters: []) {
            edges {
              node {
                id
                providerId
                serviceId
                cancellationReason
                # eslint-disable-next-line relay/unused-fields
                payments {
                  id
                  amount
                  status
                  currency
                  paymentMethod
                  type
                }
                fieldsData {
                  id
                  rowId
                  name
                  # eslint-disable-next-line relay/unused-fields
                  type
                  value
                }
                # eslint-disable-next-line relay/unused-fields
                amountPaid
                clientId
                # eslint-disable-next-line relay/unused-fields
                createdBy
                service {
                  title
                  minSlotsRequired
                }
                provider {
                  name
                }
                timeSlots {
                  id
                  startAt
                  endAt
                  date
                  # eslint-disable-next-line relay/unused-fields
                  duration
                }
                startAt
                status
                endAt
                isRecurring
                client {
                  id
                  name
                  # eslint-disable-next-line relay/unused-fields
                  phoneNumber
                }
              }
            }
          }
          places(first: 1, limit: 1) {
            edges {
              node {
                id
                # eslint-disable-next-line relay/unused-fields
                settings {
                  showServiceCategories
                }
                paymentMethods {
                  id
                  rowId
                  name
                }
              }
            }
          }
          clients(first: 5000, limit: 5000) @connection(key: "AppointmentModal_clients") {
            edges {
              node {
                id
                rowId
                name
                # eslint-disable-next-line relay/unused-fields
                lastActiveAt
                # eslint-disable-next-line relay/unused-fields
                phoneNumber
                # eslint-disable-next-line relay/unused-fields
                profilePhotoUrl
              }
            }
          }
          # TODO: $fetchTimeSlots
          timeSlots(filterBy: $timeSlotsFilterBy, first: $first) @include(if: $fetchTimeSlots) {
            edges {
              node {
                id
                date
                # eslint-disable-next-line relay/unused-fields
                duration
                startAt
                endAt
                # eslint-disable-next-line relay/unused-fields
                recurringTimeSlotId
                # provider {
                #   id
                #   name
                # }
                providerId
                serviceId
                clientId
              }
            }
          }
          services(first: 1000, limit: 1000) {
            edges {
              node {
                id
                rowId
                # eslint-disable-next-line relay/unused-fields
                description
                title
                category {
                  id
                  name
                }
                appointmentFields {
                  rowId
                  name
                  # eslint-disable-next-line relay/unused-fields
                  required
                  # eslint-disable-next-line relay/unused-fields
                  type
                  # eslint-disable-next-line relay/unused-fields
                  prompt
                }
                # eslint-disable-next-line relay/unused-fields
                duration
                # eslint-disable-next-line relay/unused-fields
                profilePhotoUrl
                isRecurring
                providers(first: 1000) {
                  edges {
                    node {
                      id
                      rowId
                      # eslint-disable-next-line relay/unused-fields
                      name
                      # eslint-disable-next-line relay/unused-fields
                      profilePhotoUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  AppointmentModalQuery,
);

AppointmentModalContainer.getVariables = (props) => {
  const {appointmentIds} = props;

  if (appointmentIds && appointmentIds.length > 0) {
    refetchVariables.fetchAppointments = true;
    refetchVariables.filterBy = {appointmentIds};
  }

  // TODO: Investigate if this has to be here
  // refetchVariables.timeSlotsFilterBy = {
  //   date: {
  //     from: startAt.format('YYYY-MM-DD'),
  //     to: startAt.add(14, 'days').format('YYYY-MM-DD'),
  //   },
  //   serviceId: appointment.serviceId,
  //   includeAll: false, // TODO: Should be true?
  //   clientId: appointment.clientId,
  //   providerId: appointment.providerId,
  // };

  // refetchVariables.fetchTimeSlots = true;

  return refetchVariables;
};

export default withRelay(AppointmentModalContainer, AppointmentModalQuery);

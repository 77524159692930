/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlaceSettings_organization$ref: FragmentReference;
declare export opaque type PlaceSettings_organization$fragmentType: PlaceSettings_organization$ref;
export type PlaceSettings_organization = {|
  +places: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +settings: ?{|
      +currency: ?string,
      +locale: ?string,
      +timezone: ?string,
      +viewType: ?string,
      +showServiceCategories: ?boolean,
      +waitlist: ?boolean,
    |},
  |}>,
  +$refType: PlaceSettings_organization$ref,
|};
export type PlaceSettings_organization$data = PlaceSettings_organization;
export type PlaceSettings_organization$key = {
  +$data?: PlaceSettings_organization$data,
  +$fragmentRefs: PlaceSettings_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceSettings_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "places",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PlaceSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timezone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showServiceCategories",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "waitlist",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '74335b9e208fdfa181f143bb24f2d4ae';

module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Calendar_viewer$ref = any;
export type ProviderAppointmentFilterInput = {|
  appointmentIds?: ?$ReadOnlyArray<?string>,
  date?: ?RangeDateFilter,
  status?: ?$ReadOnlyArray<?string>,
  providerId?: ?string,
  isPartOfRecurringSequence?: ?boolean,
  clientId?: ?string,
  excludeRecurringServices?: ?boolean,
|};
export type RangeDateFilter = {|
  from?: ?string,
  to?: ?string,
|};
export type CalendarQuery_QueryVariables = {|
  appointmentId?: ?string,
  filterBy?: ?ProviderAppointmentFilterInput,
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
|};
export type CalendarQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Calendar_viewer$ref
  |}
|};
export type CalendarQuery_Query = {|
  variables: CalendarQuery_QueryVariables,
  response: CalendarQuery_QueryResponse,
|};
*/


/*
query CalendarQuery_Query(
  $appointmentId: ID
  $filterBy: ProviderAppointmentFilterInput
  $orderBy: [[String]]
) {
  viewer {
    __typename
    ...Calendar_viewer_Pqkui
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment Calendar_viewer_Pqkui on User {
  __isUser: __typename
  ... on Provider {
    id
    workHours {
      openTime
      closeTime
      id
    }
    appointment(id: $appointmentId) {
      id
      serviceId
      providerId
      service {
        title
        capacity
        id
      }
      startAt
      status
      endAt
      isRecurring
      recurringTimeSlot {
        capacity
        id
      }
      client {
        id
        name
      }
    }
    appointments(filterBy: $filterBy, orderBy: $orderBy, first: 10000, limit: 5000) {
      edges {
        node {
          id
          providerId
          serviceId
          service {
            title
            capacity
            id
          }
          amountPaid
          startAt
          status
          endAt
          isPartOfRecurringSequence
          isRecurring
          recurringTimeSlot {
            capacity
            id
          }
          provider {
            id
            name
          }
          client {
            id
            name
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    providers(orderBy: [["createdAt", "ASC"]], first: 10000) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appointmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
},
v2 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v7/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeSlot",
  "kind": "LinkedField",
  "name": "recurringTimeSlot",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v14 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v17 = [
  (v1/*: any*/),
  (v16/*: any*/),
  {
    "kind": "Literal",
    "name": "limit",
    "value": 5000
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CalendarQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "appointmentId",
                "variableName": "appointmentId"
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Calendar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CalendarQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkHour",
                "kind": "LinkedField",
                "name": "workHours",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "openTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "closeTime",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "appointmentId"
                  }
                ],
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "appointment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "AppointmentConnection",
                "kind": "LinkedField",
                "name": "appointments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AppointmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Appointment",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v5/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountPaid",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isPartOfRecurringSequence",
                            "storageKey": null
                          },
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Provider",
                            "kind": "LinkedField",
                            "name": "provider",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          },
                          (v15/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UFPageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Calendar_appointments",
                "kind": "LinkedHandle",
                "name": "appointments"
              },
              {
                "alias": null,
                "args": [
                  (v16/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": [
                      [
                        "createdAt",
                        "ASC"
                      ]
                    ]
                  }
                ],
                "concreteType": "ProviderConnection",
                "kind": "LinkedField",
                "name": "providers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProviderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Provider",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "providers(first:10000,orderBy:[[\"createdAt\",\"ASC\"]])"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bbdd0a680a0fce14e8a657a2c48e8841",
    "id": null,
    "metadata": {},
    "name": "CalendarQuery_Query",
    "operationKind": "query",
    "text": "query CalendarQuery_Query(\n  $appointmentId: ID\n  $filterBy: ProviderAppointmentFilterInput\n  $orderBy: [[String]]\n) {\n  viewer {\n    __typename\n    ...Calendar_viewer_Pqkui\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment Calendar_viewer_Pqkui on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    workHours {\n      openTime\n      closeTime\n      id\n    }\n    appointment(id: $appointmentId) {\n      id\n      serviceId\n      providerId\n      service {\n        title\n        capacity\n        id\n      }\n      startAt\n      status\n      endAt\n      isRecurring\n      recurringTimeSlot {\n        capacity\n        id\n      }\n      client {\n        id\n        name\n      }\n    }\n    appointments(filterBy: $filterBy, orderBy: $orderBy, first: 10000, limit: 5000) {\n      edges {\n        node {\n          id\n          providerId\n          serviceId\n          service {\n            title\n            capacity\n            id\n          }\n          amountPaid\n          startAt\n          status\n          endAt\n          isPartOfRecurringSequence\n          isRecurring\n          recurringTimeSlot {\n            capacity\n            id\n          }\n          provider {\n            id\n            name\n          }\n          client {\n            id\n            name\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    providers(orderBy: [[\"createdAt\", \"ASC\"]], first: 10000) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9311aedfdd1c7df007f430fded3d4763';

module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecurringAppointmentModal_viewer$ref: FragmentReference;
declare export opaque type RecurringAppointmentModal_viewer$fragmentType: RecurringAppointmentModal_viewer$ref;
export type RecurringAppointmentModal_viewer = {|
  +__typename: "Provider",
  +id: string,
  +appointment: ?{|
    +id: string,
    +rowId: string,
    +status: ?string,
    +startAt: ?any,
    +endAt: ?any,
    +providerId: ?number,
    +clientId: ?number,
    +cancellationReason: ?string,
    +fieldsData: ?$ReadOnlyArray<?{|
      +id: string,
      +rowId: number,
      +name: ?string,
      +type: ?string,
      +value: ?string,
    |}>,
    +timeSlots: ?$ReadOnlyArray<?{|
      +id: string,
      +startAt: ?any,
      +endAt: ?any,
      +date: ?any,
      +duration: ?number,
    |}>,
    +serviceId: ?number,
    +isPartOfRecurringSequence: ?boolean,
    +formattedRecurrencePattern: ?string,
    +createdBy: ?any,
    +service: ?{|
      +id: string,
      +title: ?string,
      +appointmentFields: ?$ReadOnlyArray<?{|
        +rowId: number,
        +name: ?string,
        +required: ?boolean,
        +type: ?string,
        +prompt: ?string,
      |}>,
      +description: ?string,
    |},
    +client: ?{|
      +id: string,
      +name: ?string,
      +phoneNumber: ?string,
      +profilePhotoUrl: ?string,
    |},
    +provider: ?{|
      +id: string,
      +name: ?string,
    |},
  |},
  +clients: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +rowId: number,
        +name: ?string,
        +phoneNumber: ?string,
        +profilePhotoUrl: ?string,
      |}
    |}>
  |},
  +timeSlots?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +date: ?any,
        +duration: ?number,
        +startAt: ?any,
        +endAt: ?any,
        +providerId: ?string,
        +serviceId: ?string,
        +clientId: ?string,
      |}
    |}>
  |},
  +services: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +rowId: number,
        +description: ?string,
        +title: ?string,
        +category: ?{|
          +id: string,
          +name: ?string,
        |},
        +duration: ?number,
        +profilePhotoUrl: ?string,
        +providers: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +rowId: number,
              +name: ?string,
              +profilePhotoUrl: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: RecurringAppointmentModal_viewer$ref,
|} | {|
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  +__typename: "%other",
  +$refType: RecurringAppointmentModal_viewer$ref,
|};
export type RecurringAppointmentModal_viewer$data = RecurringAppointmentModal_viewer;
export type RecurringAppointmentModal_viewer$key = {
  +$data?: RecurringAppointmentModal_viewer$data,
  +$fragmentRefs: RecurringAppointmentModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
},
v16 = [
  (v1/*: any*/),
  (v7/*: any*/)
],
v17 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "appointmentId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchTimeSlots"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isPartOfRecurringSequence"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timeSlotsFilterBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "clients"
        ]
      }
    ]
  },
  "name": "RecurringAppointmentModal_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "appointmentId"
            },
            {
              "kind": "Variable",
              "name": "isPartOfRecurringSequence",
              "variableName": "isPartOfRecurringSequence"
            }
          ],
          "concreteType": "Appointment",
          "kind": "LinkedField",
          "name": "appointment",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cancellationReason",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AppointmentField",
              "kind": "LinkedField",
              "name": "fieldsData",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeSlot",
              "kind": "LinkedField",
              "name": "timeSlots",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/)
              ],
              "storageKey": null
            },
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isPartOfRecurringSequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formattedRecurrencePattern",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdBy",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Service",
              "kind": "LinkedField",
              "name": "service",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AppointmentField",
                  "kind": "LinkedField",
                  "name": "appointmentFields",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "required",
                      "storageKey": null
                    },
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "prompt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v13/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v7/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Provider",
              "kind": "LinkedField",
              "name": "provider",
              "plural": false,
              "selections": (v16/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "clients",
          "args": [
            {
              "kind": "Literal",
              "name": "limit",
              "value": 5000
            }
          ],
          "concreteType": "ClientConnection",
          "kind": "LinkedField",
          "name": "__RecurringAppointmentModal_clients_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v7/*: any*/),
                    (v14/*: any*/),
                    (v15/*: any*/),
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UFPageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "__RecurringAppointmentModal_clients_connection(limit:5000)"
        },
        {
          "alias": null,
          "args": [
            (v17/*: any*/),
            {
              "kind": "Literal",
              "name": "limit",
              "value": 1000
            }
          ],
          "concreteType": "ServiceConnection",
          "kind": "LinkedField",
          "name": "services",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ServiceEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Service",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v13/*: any*/),
                    (v12/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ServiceCategory",
                      "kind": "LinkedField",
                      "name": "category",
                      "plural": false,
                      "selections": (v16/*: any*/),
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    (v15/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        (v17/*: any*/)
                      ],
                      "concreteType": "ProviderConnection",
                      "kind": "LinkedField",
                      "name": "providers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProviderEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Provider",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v7/*: any*/),
                                (v15/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "providers(first:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "services(first:1000,limit:1000)"
        },
        {
          "condition": "fetchTimeSlots",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterBy",
                  "variableName": "timeSlotsFilterBy"
                },
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "first"
                }
              ],
              "concreteType": "TimeSlotConnection",
              "kind": "LinkedField",
              "name": "timeSlots",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeSlotEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TimeSlot",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v11/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();
// prettier-ignore
(node/*: any*/).hash = '77e553ca16608ee83db972ecafa6c389';

module.exports = node;

import './PlaceSettings.css';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {Checkbox, Select} from 'semantic-ui-react';
import {useFragment} from 'relay-hooks';
import {withRelay} from '../../relay';
import {languageOptions, timezoneOptions, currencyOptions} from './constants';
import Dropdown from './Dropdown';
import isMatchWith from '../../helpers/isMatchWith';
import {UpdatePlacesSettingsMutation} from '../../mutations';
import {useTranslation} from 'react-i18next';
import BottomBar from './BottomBar';

function PlaceSettings(props) {
  const organization = useFragment(
    graphql`
      fragment PlaceSettings_organization on Organization {
        places {
          id
          name
          # eslint-disable-next-line relay/unused-fields
          settings {
            currency
            locale
            timezone
            viewType
            showServiceCategories
            waitlist
          }
        }
      }
    `,
    props.viewer.organization,
  );
  const {places} = organization;

  const [placesState, setPlacesState] = React.useState((places || []).map((place) => ({id: place.id})));
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = React.useState(false);

  const {t} = useTranslation();

  const viewTypeOptions = [
    {
      value: 'grid',
      key: 'grid',
      text: t('common.view_type_grid'),
    },
    {
      value: 'regular',
      key: 'regular',
      text: t('common.view_type_regular'),
    },
  ];

  function handleFieldChange(e, data) {
    if (!data.name) {
      return;
    }

    const [index, prop] = data.name.split('.');

    setPlacesState((prevState) => {
      const placesArray = prevState.map((place, i) => {
        if (i === Number(index)) {
          return {...place, [prop]: data.toggle ? data.checked : data.value};
        } else {
          return place;
        }
      });

      return placesArray;
    });
  }

  function hasUnsavedChanges() {
    const placesSettings = places.map((place) => ({...place.settings, id: place.id}));

    return !isMatchWith(placesSettings, placesState);
  }

  function handleSubmit() {
    setIsSubmitButtonLoading(true);

    const onFailure = (errors) => {
      console.log('error', errors);
      setIsSubmitButtonLoading(false);
    };

    const onSuccess = (data) => {
      setPlacesState((places || []).map((place) => ({id: place.id})));
      setIsSubmitButtonLoading(false);
    };

    const input = {
      placesSettings: placesState,
    };

    UpdatePlacesSettingsMutation({input}, onSuccess, onFailure);
  }

  return (
    <div className="container">
      <BottomBar isVisible={hasUnsavedChanges()} isLoading={isSubmitButtonLoading} handleSubmit={handleSubmit} />
      {places.map((place, index) => {
        const {locale, timezone, currency, showServiceCategories, viewType, waitlist} = place.settings;
        return (
          <div className="place-container" key={index}>
            <p>{place.name}</p>
            <div className="general-container">
              <Dropdown
                defaultValue={locale}
                selection
                name={`${index}.locale`}
                label={t('common.language')}
                placeholder={t('actions.select_language')}
                options={languageOptions}
                onChange={handleFieldChange}
              />
              <Dropdown
                defaultValue={timezone}
                selection
                name={`${index}.timezone`}
                onChange={handleFieldChange}
                placeholder={t('common.timezone')}
                label={t('common.timezone')}
                options={timezoneOptions}
              />
            </div>
            <div className="view-type-container">
              <p>{t('common.main_view')}</p>
              <Select
                name={`${index}.viewType`}
                defaultValue={viewType}
                onChange={handleFieldChange}
                options={viewTypeOptions}
              />
            </div>
            <div className="settings-row">
              <p>{t('common.show_service_category')}</p>
              <Checkbox
                name={`${index}.showServiceCategories`}
                toggle
                onChange={handleFieldChange}
                defaultChecked={showServiceCategories}
              />
            </div>
            <div className="currency-container">
              <p>{t('common.currency')}</p>
              <Select
                name={`${index}.currency`}
                defaultValue={currency}
                onChange={handleFieldChange}
                options={currencyOptions}
              />
            </div>
            <div className="settings-row">
              <p>{t('common.waitlist')}</p>
              <Checkbox name={`${index}.waitlist`} toggle onChange={handleFieldChange} defaultChecked={waitlist} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

const PlaceSettingsQuery = graphql`
  query PlaceSettingsQuery {
    viewer {
      ... on Provider {
        organization {
          ...PlaceSettings_organization
        }
      }
    }
  }
`;

PlaceSettings.getVariables = (props) => {};

export default withRelay(PlaceSettings, PlaceSettingsQuery);

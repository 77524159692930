/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ClientView_viewer$ref = any;
export type ProviderAppointmentFilterInput = {|
  appointmentIds?: ?$ReadOnlyArray<?string>,
  date?: ?RangeDateFilter,
  status?: ?$ReadOnlyArray<?string>,
  providerId?: ?string,
  isPartOfRecurringSequence?: ?boolean,
  clientId?: ?string,
  excludeRecurringServices?: ?boolean,
|};
export type RangeDateFilter = {|
  from?: ?string,
  to?: ?string,
|};
export type ClientViewQuery_QueryVariables = {|
  clientId?: ?string,
  filterBy?: ?ProviderAppointmentFilterInput,
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  cursor?: ?string,
  count?: ?number,
|};
export type ClientViewQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ClientView_viewer$ref
  |}
|};
export type ClientViewQuery_Query = {|
  variables: ClientViewQuery_QueryVariables,
  response: ClientViewQuery_QueryResponse,
|};
*/


/*
query ClientViewQuery_Query(
  $clientId: ID
  $filterBy: ProviderAppointmentFilterInput
  $orderBy: [[String]]
  $cursor: String
  $count: Int
) {
  viewer {
    __typename
    ...ClientView_viewer_4AMYuD
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment AppointmentRow_appointment on Appointment {
  startAt
  endAt
  duration
  service {
    title
    id
  }
}

fragment ClientView_viewer_4AMYuD on User {
  __isUser: __typename
  ... on Provider {
    client(id: $clientId) {
      id
      name
      lastActiveAt
      createdAt
      totalAppointmentsCount
      phoneNumber
      profilePhotoUrl
    }
    appointments(filterBy: $filterBy, orderBy: $orderBy, first: $count, after: $cursor) {
      edges {
        node {
          id
          ...AppointmentRow_appointment
          startAt
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "cursor"
},
v6 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
},
v7 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientViewQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "clientId",
                "variableName": "clientId"
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ClientView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientViewQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "clientId"
                  }
                ],
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastActiveAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalAppointmentsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePhotoUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "AppointmentConnection",
                "kind": "LinkedField",
                "name": "appointments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AppointmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Appointment",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Service",
                            "kind": "LinkedField",
                            "name": "service",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UFPageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "filterBy",
                  "orderBy"
                ],
                "handle": "connection",
                "key": "ClientView_appointments",
                "kind": "LinkedHandle",
                "name": "appointments"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v9/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c6bc6f6d2ae0294eb3b240b926e1c43",
    "id": null,
    "metadata": {},
    "name": "ClientViewQuery_Query",
    "operationKind": "query",
    "text": "query ClientViewQuery_Query(\n  $clientId: ID\n  $filterBy: ProviderAppointmentFilterInput\n  $orderBy: [[String]]\n  $cursor: String\n  $count: Int\n) {\n  viewer {\n    __typename\n    ...ClientView_viewer_4AMYuD\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AppointmentRow_appointment on Appointment {\n  startAt\n  endAt\n  duration\n  service {\n    title\n    id\n  }\n}\n\nfragment ClientView_viewer_4AMYuD on User {\n  __isUser: __typename\n  ... on Provider {\n    client(id: $clientId) {\n      id\n      name\n      lastActiveAt\n      createdAt\n      totalAppointmentsCount\n      phoneNumber\n      profilePhotoUrl\n    }\n    appointments(filterBy: $filterBy, orderBy: $orderBy, first: $count, after: $cursor) {\n      edges {\n        node {\n          id\n          ...AppointmentRow_appointment\n          startAt\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff2bf371d9f62faa27989d578506bcee';

module.exports = node;
